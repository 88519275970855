/* stylelint-disable */
/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #3B82F6;
  --secondary-color: #8B5CF6;
}

/* Reset default margins and ensure proper box sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1F2937;
  background-color: #F3F4F6;
}


/* Ensure #root takes full height */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.pulse-animation {
  animation: pulse 2s infinite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom utility classes */
.gradient-bg {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.hover-scale {
  transition: transform 0.3s ease-in-out;
}

.hover-scale:hover {
  transform: scale(1.05);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

/* Improved button styles */
.btn {
  @apply font-bold py-2 px-4 rounded transition duration-300 ease-in-out;
}

.btn-primary {
  @apply bg-blue-600 text-white hover:bg-blue-700;
}

.btn-secondary {
  @apply bg-purple-600 text-white hover:bg-purple-700;
}

/* Card styles */
.card {
  @apply bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300;
}

.wizard-enter {
  transform: translateX(100%);
}
.wizard-enter-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.wizard-exit {
  transform: translateX(0);
}
.wizard-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #60A5FA;
    --secondary-color: #A78BFA;
  }

  body {
    color: #E5E7EB;
    background-color: #111827;
  }
}

html {
  scroll-behavior: smooth;
}

.wizard-overlay-enter {
  opacity: 0;
}
.wizard-overlay-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
.wizard-overlay-exit {
  opacity: 1;
}
.wizard-overlay-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

input, select, textarea {
  @apply border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-between {
  @apply flex items-center justify-between;
}

*:focus-visible {
  outline: 2px solid #4299e1;
  outline-offset: 2px;
}

@layer components {
  /* Typography Styles */
  .text-h1 {
    @apply text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900;
  }

  .text-h2 {
    @apply text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900;
  }

  .text-h3 {
    @apply text-2xl md:text-3xl lg:text-4xl font-semibold text-gray-900;
  }

  .text-h4 {
    @apply text-xl md:text-2xl lg:text-3xl font-semibold text-gray-900;
  }

  .text-body-lg {
    @apply text-lg font-normal text-gray-800;
  }

  .text-body {
    @apply text-base font-normal text-gray-800;
  }

  .text-body-sm {
    @apply text-sm font-normal text-gray-700;
  }

  .text-caption {
    @apply text-xs font-normal text-gray-600;
  }


  /* Add wizard-specific typography */
  .wizard-heading {
    @apply text-3xl md:text-3xl font-bold text-gray-900 leading-tight;
  }

  .wizard-subheading {
    @apply text-lg md:text-2xl font-medium text-gray-700 leading-relaxed;
  }

  .wizard-text {
    @apply text-base text-gray-600 leading-relaxed;
  }

}

/* Updated ripple animation */
@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.95);
    opacity: 0.5;
  }
}

.ripple-circle {
  position: absolute;
  border-radius: 50%;
  transform-origin: center;
  animation: ripple 4s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

/* Enhanced ripple container styling */
.ripple-container {
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Additional utility classes for layout control */
.layout-container {
  @apply flex-grow flex flex-col min-h-screen;
}

.content-container {
  @apply flex-grow flex flex-col;
}

/* Ensure proper spacing in marketplace layout */
.marketplace-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.marketplace-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
}

/* Hero section specific styles */
.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  min-height: calc(100vh - 74px); /* Accounts for both headers */
}

/* Ripple effects specific styles */
.ripple-gradient {
  background: linear-gradient(to right, rgba(139, 92, 246, 0.3), rgba(59, 130, 246, 0.3));
  border: 2px solid rgba(139, 92, 246, 0.1);
}